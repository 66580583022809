//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getbehaviorColor, getEfficiencyColor, imgChange } from "@/commons";
import { mapState } from "vuex";

export default {
  components: {
    XHeader: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    tooltip: () => import("@/components/textTooltip"),
    progresscom: () => import("./progressCom"),
    allsoftware: () => import("./allsoftware"),
    keywordfrequency: () => import("./keywordfrequency"),
    staffData: () => import("./staffData"),
    Staechart: () => import("./workstatus-pieecharts"),
    AllWords: () => import("./allwords"),
    Ranking: () => import("./ranking"),
    AllBehavior: () => import("./allbehavior"),
    BehaviorEcharts: () => import("./behavior-echarts"),
  },
  data() {
    return {
      isNoMore: false,
      selActiveTime: null,
      isTimeSearch: false, //是否按时间查询
      selUser: null, //选择的成员数据列表成员
      DateRange: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        new Date(),
      ],
      imgload: false,
      userID: "",
      timer: null,
      IsRealTimeScreenshot: false, //是否显示实时截图
      IsStartCamera: false, //是否显示摄像头
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      selMem: [], //选择的成员
      pickOption: this.pickDate(),
      isListPage: true, //是否在列表页
      inputSearch: null, //搜索员工
      dateType: 1, //时间查询类型
      listdateType: 1, //卡片列表显示月、周类型
      selDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      ),
      selDepart: [], //选择的部门
      selWorkItem: null, //点击工作状态5个分类的某一个分类
      loading: false, //加载
      busy: true,
      memberData: [], //成员数据
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        pageNum: 0,
      }, //分页
      clickUser: null, //点击的用户
      clickUserName: null, //点击的用户名
      etime: null,
      stime: null,
      clickKeyWord: null, //要查看的关键词
      pname: null, //选择的关键词的窗口名
      isShowTeam: false, // 是否显示团队选择
      gid: null, //进程组id
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    this.$E.$off("loadpic");
    this.$E.$off("loadingpic");
    this.$E.$off("loadcamerapic");
    this.$E.$off("loadingcamerapic");
  },
  mounted() {
    const role = this.$xStorage.getItem("user-role");
    if (role.team) {
      this.teamValue = role.team;
      this.isShowTeam = false;
    } else {
      this.isShowTeam = true;
    }
    this.$nextTick(() => {
      this.getTeams();
    });

    this.$E.$on("loadpic", (res) => {
      console.log("开始截图");
    });
    this.$E.$on("loadcamerapic", (res) => {
      console.log("开始摄像头拍照");
    });
    this.$E.$on("loadingcamerapic", (res) => {
      this.socketPic(res, "摄像头拍照");
    });
    this.$E.$on("loadingpic", (res) => {
      this.socketPic(res, "屏幕截图");
    });
  },
  methods: {
    imgChange,
    getEfficiencyColor,
    getbehaviorColor,
    /**
     * 操作效率折线图
     */
    getChart(id) {
      // 基于准备好的dom，初始化echarts实例
      var echarts = require("echarts");
      var chartDom = document.getElementById(`chart-${id}`);
      var myChart = echarts.init(chartDom);

      let option = {
        grid: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          show: false,
        },
        yAxis: {
          type: "value",
          max: 10,
          show: false,
        },
        series: [
          {
            type: "line",
            smooth: true,
            data: [3, 1, 1, 1, 1, 1, 2],
            symbol: "none",

            lineStyle: {
              color: this.getEfficiencyColor("消极"),
            },
            areaStyle: {
              color: this.getEfficiencyColor("消极"),
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    /**
     * websocket截图回调
     */
    socketPic(res, type) {
      if (res.teamId != this.teamValue) {
        return;
      }
      console.log(`收到${type}`);
      this.imgload = false;
      this.memberData.forEach((m) => {
        if (m.User.id == res.UserId) {
          let shotArr = JSON.parse(res.imgUrl);
          if (shotArr && shotArr.length) {
            shotArr.forEach((val) => {
              m.loadPic.push(val);
            });
          } else {
            this.$message({
              showClose: true,
              message: `没有接收到${type}`,
              type: "warning",
            });
          }
        }
      });
    },
    /**
     * 行为分析标签颜色
     * 积极：绿  消极：红  无：白色
     */
    getTagType(val) {
      if (val == "积极") {
        return "success";
      } else if (val == "消极") {
        return "danger";
      } else {
        return "info";
      }
    },
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.IsRealTimeScreenshot = team.IsRealTimeScreenshot; //是否显示实时截图
        this.IsStartCamera = team.IsStartCamera; //是否显示摄像头
      }
      this.selMem = [];
    },
    /**
     * 截图
     */
    shotScreenPhoto(id, item) {
      if (!item.screenCheck.length) {
        this.$message({
          showClose: true,
          message: "请选择截图类型",
          type: "warning",
          duration: 2000,
        });
        return;
      }
      item.loadPic = [];
      this.userID = id;
      //截图
      this.imgload = true;
      if (item.screenCheck.includes("屏幕")) {
        this.screenShot();
      }
      if (item.screenCheck.includes("摄像头")) {
        this.screenShotCamera();
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.setTimer();
    },
    /**
     * 屏幕截图
     */
    screenShot() {
      this.$http
        .get("/User/Work/NoticeUserScreenshots.ashx", {
          params: { Id: this.userID, teamId: this.teamValue },
        })
        .then((res) => {});
    },
    /**
     * 摄像头截图
     */
    screenShotCamera() {
      this.$http
        .get("/User/Work/NoticeUserPhotographs.ashx", {
          params: { Id: this.userID, teamId: this.teamValue },
        })
        .then((res) => {});
    },
    setTimer() {
      if (this.timer == null) {
        let num = 0;
        this.timer = setInterval(() => {
          num += 1;
          if (num == 30 && this.imgload == true) {
            clearInterval(this.timer);
            this.timer = null;
            this.imgload = false;
            this.$notify({
              type: "error",
              message: "请求超时",
            });
          }
        }, 1000);
      }
    },
    /**
     * 获取团队
     */
    getTeams() {
      this.$http
        .get("/Teams/GetAllTeams.ashx", {
          params: { searchText: null, type: 2 },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamOptions = resp.data;
            var defaultTeamValue = this.user.DefaultTeamId;
            if (this.teamOptions.length > 0 && defaultTeamValue) {
              var team = this.teamOptions.find((m) => m.Id == defaultTeamValue);
              if (team) {
                this.teamValue = team.Id;
              }
            }
            if (this.teamValue) {
              this.handleGetData();
            }
          }
        });
    },
    /**
     * 获取列表数据
     */
    handleGetData() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      this.pageData.pageIndex = 1;
      this.memberData = [];
      this.getMemberData();
    },
    /**
     * 时间选择
     */
    dateChange(val) {
      // console.log(this.selDate);
      // console.log(val);
    },
    /**
     * 查看数据详情
     */
    handleDataInfo(val) {
      // this.etime = val.etime;
      // this.stime = val.stime;
      this.clickUserName = val.User.name;
      this.clickUser = val.User.id;
      this.pageChange(false);
    },
    /**
     * 视图切花
     */
    pageChange(val) {
      this.isListPage = val;
    },
    /**
     * 查看某个关键词
     */
    handleKeyWord(val, item) {
      console.log(val, item);
      this.clickUser = item.User.id;
      this.clickKeyWord = val.Key;
      this.pname = val.FocusFormName;
      this.isTimeSearch = item.selActiveTime ? true : false;
      this.selActiveTime = item.selActiveTime;
      this.$modal.show("meData");
    },
    /**
     * 查看某个行为
     */
    handleBehavior(val, item) {
      console.log(val, item);
      this.clickUser = item.User.id;
      this.clickKeyWord = val.Behavoir;
      this.isTimeSearch = item.selActiveTime ? true : false;
      this.selActiveTime = item.selActiveTime;
      this.$modal.show("behaviorecharts");
    },
    /**
     * 查看全部软件
     */
    handleAllSoftware(item) {
      // this.etime = item.etime;
      // this.stime = item.stime;
      this.clickUser = item.User.id;
      this.selActiveTime = item.selActiveTime;
      this.$modal.show("allsoftware");
    },
    /**
     * 查看全部行为分析
     */
    handleAllBehavior(item) {
      this.selUser = item;
      this.selActiveTime = item.selActiveTime;
      this.$modal.show("allbehavior");
    },
    /**
     * 查看全部关键词
     */
    handleAllWords(item) {
      this.selUser = item;
      this.$modal.show("allwords");
    },
    scrollLoad() {
      this.busy = true;
      this.pageData.pageIndex++;
      this.getMemberData();
    },
    /**
     *获取数据
     */
    getMemberData() {
      this.loading = true;
      let wk = null;
      //如果是按周查询，则wk获取组件传回的天数的前一天，按月查询，wk为组件传回的日期
      if (this.dateType == 1) {
        const seldate = new Date(this.selDate);
        wk = new Date(seldate.setDate(seldate.getDate() - 1)).timeFormat(
          "yyyy-MM-dd"
        );
      } else if (this.dateType == 2) {
        wk = this.selDate;
      } else {
        wk =
          this.DateRange && this.DateRange.length
            ? this.DateRange[0].timeFormat("yyyy-MM-dd")
            : null;
      }
      const data = {
        p: this.pageData.pageIndex,
        c: this.pageData.pageSize,
        wk: wk,
        dt: this.dateType,
        us: this.selMem.map((m) => m.UsId),
        teamId: this.teamValue,
        edate:
          this.dateType == 3 && this.DateRange && this.DateRange.length
            ? this.DateRange[1].timeFormat("yyyy-MM-dd")
            : null,
      };
      this.$http
        .post("/User/MemberPeriod.ashx#", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.listdateType = this.dateType;
            if (resp.data.items.length) {
              //获取开始时间和结束时间，用于其他功能的查询
              this.stime = resp.data.items[0].stime;
              if (this.dateType == 1) {
                const seldate = new Date(resp.data.items[0].etime);
                this.etime = new Date(
                  seldate.setDate(seldate.getDate() - 1)
                ).timeFormat("yyyy-MM-dd 23:59:59");
              } else {
                this.etime = resp.data.items[0].etime.timeFormat(
                  "yyyy-MM-dd 23:59:59"
                );
              }
              console.log(this.stime, this.etime);
              resp.data.items.forEach((element) => {
                element.screenCheck = [];
                element.loadPic = [];
                if (element.ComputerUsageRecord) {
                  element.ComputerUsageRecord.workRat =
                    element.ComputerUsageRecord.workRat.map((m) => {
                      return {
                        name: m.name,
                        value: m ? m.value.toFixed(1) : 0,
                      };
                    });
                }
              });
            }
            this.memberData.push(...resp.data.items);
            if (resp.data.items.length < this.pageData.pageSize) {
              this.isNoMore = true;
              this.busy = true;
            } else {
              this.busy = false;
              this.isNoMore = false;
            }
            // this.$nextTick(() => {
            //   this.memberData.forEach((m) => {
            //     this.getChart(m.User.id);
            //   });
            // });
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
    },
    /**
     /**
     * 选择部门
     */
    getDepart(arr) {
      this.selDepart = arr.map((m) => m.RId);
    },
    pickDate() {
      let _this = this;
      return {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
      };
    },
    /**
     * 点击工作状态进度
     */
    getBarData(item, data) {
      if (item.name == "离线") {
        this.$message({
          type: "warning",
          message: "无法查看离线状态的详情",
        });
        return;
      }
      // this.etime = data.etime;
      // this.stime = data.stime;
      this.clickUser = data.clickUser;
      this.selWorkItem = item;
      this.gid = data.gid;
      this.$nextTick(() => {
        this.$modal.show("memberDatatimeAxisPhoto");
      });
    },
  },
};
